import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98')
];

export const server_loads = [0,9,10,16,2,5,22,24,25,3];

export const dictionary = {
		"/(landings)/(home)": [~96],
		"/(iam)/auth/edu": [~89,[28]],
		"/(iam)/auth/password": [~90],
		"/(iam)/auth/recover": [~91,[27]],
		"/(iam)/auth/signin": [~92,[27]],
		"/(iam)/auth/signup": [~93,[27]],
		"/(hub)/console": [43,[9]],
		"/(hub)/console/cms/[resource_id]/richtext": [44,[10]],
		"/(hub)/console/cms/[resource_id]/text": [~45,[11]],
		"/(hub)/console/codes": [~46,[9]],
		"/(hub)/console/codes/[id]": [~47,[12]],
		"/(hub)/console/crm/feedback": [~48,[9]],
		"/(hub)/console/crm/partnership": [~49,[9]],
		"/(hub)/console/crm/subscription": [~50,[9]],
		"/(hub)/console/feed": [~51,[9]],
		"/(hub)/console/feed/[post_id]/edit": [~52,[9]],
		"/(hub)/console/organizations": [~53,[9]],
		"/(hub)/console/organizations/[id]": [~54,[13]],
		"/(hub)/console/organizations/[id]/groups": [~55,[13]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~56,[14]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~57,[14]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~58,[14]],
		"/(hub)/console/organizations/[id]/roles": [~59,[13]],
		"/(hub)/console/terms": [~60,[9]],
		"/(hub)/console/terms/[id]": [~61,[15]],
		"/(hub)/console/terms/[id]/blueprints": [~62,[15]],
		"/(hub)/console/terms/[id]/changes": [~63,[15]],
		"/(hub)/console/users": [~64,[9]],
		"/(hub)/dev": [65,[16],[17]],
		"/(hub)/dev/examples": [~66,[18]],
		"/(hub)/dev/examples/await": [67,[18]],
		"/(hub)/dev/examples/scheduler": [68,[18]],
		"/(hub)/dev/examples/seaweedfs": [69,[18]],
		"/(hub)/dev/examples/xapi": [70,[18]],
		"/(hub)/dev/examples/yjs": [71,[18]],
		"/(hub)/dev/playground": [~72,[19]],
		"/(hub)/dev/playground/buttons": [73,[19]],
		"/(hub)/dev/playground/confirm": [74,[19]],
		"/(hub)/dev/playground/dates": [75,[19]],
		"/(hub)/dev/playground/inputs": [~76,[19]],
		"/(hub)/dev/playground/modal": [77,[19]],
		"/(hub)/dev/playground/popover": [78,[19]],
		"/(hub)/dev/playground/switch": [79,[19]],
		"/(hub)/dev/playground/tabs copy": [81,[19]],
		"/(hub)/dev/playground/tabs": [80,[19]],
		"/(hub)/dev/playground/toasts": [82,[19]],
		"/(education)/events": [34,[4]],
		"/(landings)/faststart": [97],
		"/(education)/feed": [~35,[2]],
		"/(education)/groups": [~36,[2]],
		"/(education)/groups/[id]": [~37,[5]],
		"/(education)/groups/[id]/terms": [~38,[5]],
		"/(education)/home": [~39,[6]],
		"/legal/user-agreement": [98,[30]],
		"/(hub)/lms": [83,[20],[21]],
		"/(hub)/lms/[type=blueprint_type]": [~84,[20],[21]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [86,[22,24,25],[23]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [87,[22,24],[23]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~85,[22,24],[23]],
		"/(hub)/mentorship": [88,[26]],
		"/(education)/org/contest": [40,[7]],
		"/(education)/rewards": [~41,[2]],
		"/(education)/rewards/[reward_id]": [~42,[2],[,8]],
		"/(iam)/user": [~94,[29]],
		"/(iam)/user/security": [~95,[29]],
		"/(education)/[type=term_type]": [~31,[2]],
		"/(education)/[type=term_type]/[slug]": [~32,[3]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~33,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';